import {
	Stack,
	ThemeProvider,
	CssBaseline,
	Typography,
	useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import Nav from "../components/navigation/Nav";
import { theme } from "../themes/themes"
import { graphql } from 'gatsby'
import PageContainer from "../components/layout/PageContainer";
import Seo from "../components/Seo";
import { prettifiedProduct } from "../utils/helpers/prettificationHelpers";
import CompareButton from "../components/navigation/CompareButton";
import { useAtom } from "jotai";
import { breakpointsAtom } from "../utils/state/devicesState";
import { getBreakpointMatch, resolutionAxes } from "../utils/helpers/breakpointsHelpers";
import ProductSubNav from "../components/navigation/ProductSubNav";
import ProductSection from "../components/layout/ProductSection";
import ProductPageDisplaySection from "../components/layout/ProductPageDisplaySection";
import ProductPageCameraSection from "../components/layout/ProductPageCameraSection";
import ProductPageDesignSection from "../components/layout/ProductPageDesignSection";
import ProductPageTechSection from "../components/layout/ProductPageTechSection";
import Footer from "../components/navigation/Footer";

const ProductPageTemplate = ({ data, location }) => {
	const product = prettifiedProduct(data.devicesJson);
	const [breakpoints] = useAtom(breakpointsAtom)
	const isMobile = useMediaQuery(theme.breakpoints.down("md"))

	const { fullMatch } = getBreakpointMatch(breakpoints, resolutionAxes(product).width, product)

	const pageSections = [
		{
			title: "Display",
			Section: ProductPageDisplaySection
		}, {
			title: "Cameras",
			Section: ProductPageCameraSection,
		}, {
			title: "Design",
			Section: ProductPageDesignSection
		}, {
			title: "Tech",
			Section: ProductPageTechSection
		}
	]

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Seo
				location={location}
				passedTitle={`${product.Product.Model} Specs and Screen Sizes`}
				passedDescription={product.Description}
			/>
			<Nav location={location}>
				<CompareButton
					buttonLabel="Compare"
					productModel={location.pathname}
				/>
			</Nav>
			<PageContainer>
				<article>
					<Stack
						spacing={{ xs: 4, md: 6, lg: 8 }}
						sx={{
							paddingBottom: "80px",
							marginTop: "32px",
						}}
					>
						{isMobile &&
							<ProductSubNav
								product={product.Product.Model.replace(product.Product.Brand, "")}
								sections={pageSections.map(({ title }) => title)}
								sx={{
									width: "100%",
									paddingBottom: theme.spacing(1),
									position: "sticky",
									top: "60px",
									zIndex: 10,
									backgroundColor: theme.palette.surface.main,
								}}
							/>
						}
						<Stack
							direction={{ xs: "column", md: "row" }}
							spacing={6}
						>
							{!isMobile && <ProductSubNav
								product={product.Product.Model.replace(product.Product.Brand, "")}
								sections={pageSections.map(({ title }) => title)}
								sx={{
									flexShrink: 0,
									flexGrow: 0,
									width: "33%",
								}}
							/>}
							<Typography
								variant="h4"
								component="h2"
								sx={{
									width: "67%",
									[theme.breakpoints.down("md")]: {
										width: "100%"
									}
								}}
							>
								{product.Description}
							</Typography>
						</Stack>
						{pageSections.map(({ title, Section }) => {
							return (
								<ProductSection
									sectionTitle={title}
								>
									<Section
										product={product}
									/>
								</ProductSection>
							)
						})}
					</Stack>
				</article>
				<Footer />
			</PageContainer >
		</ThemeProvider >
	)
}

export const query = graphql`
    query productPageQuery($slug: String) {
		devicesJson(Product: {Model: {eq: $slug}}) {
			Category
			Camera {
				Back_Camera {
					Aperture__T_
					Aperture__W_
					Equivalent_Focal_Length
					Features
					Focus
					Resolution
					Resolution__H_x_W_
					Sensor
					Video_Format
					Video_Resolution
					Zoom
				}
				Back_Camera_II {
					Aperture__W_
					Equivalent_Focal_Length
					Features
					Focus
					Resolution
					Sensor
				}
				Back_Camera_III {
					Aperture__W_
					Equivalent_Focal_Length
					Features
					Focus
					Resolution
					Sensor
				}
				Back_Camera_IV {
					Aperture__W_
					Equivalent_Focal_Length
					Features
					Focus
					Resolution
					Sensor
				}
				Back_Camera_V {
					Sensor
				}
				Front_Camera {
					Aperture__W_
					Features
					Focus
					Minimum_Focal_Length
					Resolution
					Resolution__H_x_W_
					Sensor
					Video_Format
					Video_Resolution
				}
				Front_Camera_II {
					Aperture__W_
					Resolution
					Sensor
				}
				Front_Camera_III {
					Sensor
				}
			}
			Design {
				Color_s_
				Height
				IP_Rating
				Thickness
				Weight
				Width
			}
			Display {
				Bezel_Width
				Color_Depth
				Diagonal
				Dynamic_Range
				Glass
				Height
				Illumination
				Module
				Notch_es_
				Number_of_Colors
				Pixel_Density
				Pixel_Size
				Refresh_Rate
				Resolution__H_x_W_
				Screen_to_Body_Ratio
				Touch_Point_s_
				Touch_screen_Type
				Type
				Width
			}
			Image {
				Back
				Front
			}
			Inside {
				Battery {
					Capacity
					Cell_I
					Cell_II
					Charging_Power
					Current
					Energy
					Life
					Power_IC
					Standby_Current_Consumption
					Standby_Time
					Style
					Talk_Time
					Type
					Voltage
					Wireless_Charging
					Wireless_Charging_Power
				}
				Processor {
					CPU
					CPU_Clock_Speed
					GPU
					GPU_Clock_Speed
					GPU_Dedicated_Memory
				}
				RAM {
					Capacity
					Clock_Speed
					Module
					Type
				}
				Sensor_s_ {
					Present
				}
				Software {
					OS
					OS_Version
				}
				Storage {
					Capacity
					Expansion
					Module
					Type
				}
			}
			Product {
				Version
				SIM_Type
				Model
				Generation
				Brand
			}
			Rank {
				Price
			}
			Time {
				Announced(formatString: "DD MMMM, YYYY", fromNow: true)
				Released(formatString: "DD MMMM, YYYY", fromNow: true)
			}
		}
    }
`

export default ProductPageTemplate;
