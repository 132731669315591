import { Stack, Typography, useTheme, Box } from "@mui/material";
import React from "react";
import Card from "./Card";

const ProductDataHero = ({ fieldName, value, additionalDetails, fullSize }) => {
    const theme = useTheme()
    const circleSize = theme.spacing(50)

    return (
        <Card
            disableHover
            sx={{
                position: "relative",
                minHeight: "116px",
                overflow: "hidden",
                [theme.breakpoints.down("md")]: {
                    height: fullSize ? "512px" : "auto",
                }
            }}
        >
            {fullSize &&
                <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "-180px",
                            right: "-80px",
                            backgroundColor: theme.palette.deepOrange[200],
                            height: circleSize,
                            width: circleSize,
                            borderRadius: "100%",
                            filter: `blur(30px)`,
                            zIndex: 3,
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: "-80px",
                            left: "-150px",
                            backgroundColor: theme.palette.deepOrange[400],
                            height: theme.spacing(50),
                            width: theme.spacing(50),
                            borderRadius: "100%",
                            filter: `blur(40px)`
                        }}
                    />
                </Box>
            }
            <Stack
                justifyContent="space-between"
                spacing={2}
                sx={{
                    height: "100%",
                    zIndex: 1,
                }}
            >
                <Typography
                    variant="body2"
                    component="h4"
                    fontWeight={400}
                >
                    {fieldName}
                </Typography>
                <Stack>
                    <Typography
                        variant={fullSize ? "h3" : "h5"}
                        component="h5"
                    >
                        {value}
                    </Typography>
                    {additionalDetails && <Typography
                        fontWeight={400}
                        color={theme.palette.grey[400]}
                        variant="body2"
                    >
                        {additionalDetails}
                    </Typography>}
                </Stack>
            </Stack>
        </Card>
    )
}

export default ProductDataHero