import { Typography, Stack, Divider, useTheme, } from "@mui/material";
import React from "react";
import ProductDataHero from "./ProductDataHero";
import _ from "lodash";


const ProductDataSection = ({ groupName, heroItem, pullOutItems, otherItems }) => {
    const theme = useTheme()
    const renderSimpleItem = ({ fieldName, value }) => {
        return <Typography variant="body2" color={theme.palette.grey[600]}>{fieldName ? `${value} ${_.startCase(fieldName)}` : `${value}`}</Typography>
    }
    const hasPullOutItems = pullOutItems && pullOutItems.filter(({ value }) => value)

    return (
        <section>
            <Stack spacing={2}>
                <Typography
                    variant="body1"
                    component="h3"
                    sx={{ color: theme.palette.grey[200] }}
                >
                    {groupName}
                </Typography>
                {(pullOutItems && hasPullOutItems.length > 0) && (heroItem && heroItem.value) &&
                    <Stack
                        spacing={{ xs: 2, sm: 2, md: 2 }}
                        direction={{ sm: "column", md: "row" }}
                        sx={{
                            "> *": {
                                width: "50%"
                            },
                            [theme.breakpoints.down("md")]: {
                                "> *": {
                                    width: "100%"
                                }
                            }
                        }}
                    >
                        {heroItem && heroItem.value &&
                            <ProductDataHero
                                fieldName={heroItem.fieldName}
                                value={heroItem.value}
                                additionalDetails={heroItem.additionalDetails}
                                fullSize
                            />
                        }
                        {pullOutItems && hasPullOutItems.length > 0 &&
                            <Stack
                                spacing={2}
                            >
                                {pullOutItems.map(({
                                    fieldName,
                                    value,
                                    additionalDetails
                                }) => (
                                    <ProductDataHero
                                        fieldName={fieldName}
                                        value={value}
                                        additionalDetails={additionalDetails}
                                    />
                                ))}
                            </Stack>
                        }
                    </Stack>
                }
                {otherItems &&
                    <Stack spacing={1}>
                        <Typography variant="body2">Other features</Typography>
                        <Stack>
                            {otherItems.map(item => item.value && renderSimpleItem(item))}
                        </Stack>
                    </Stack>
                }
            </Stack>
        </section>
    )
}

export default ProductDataSection