import { Box, Button, Divider, Stack, Typography, useMediaQuery, useTheme, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import ListItemButton from "../input/ListItemButton";
import PopperOptionSelector from "../input/PopperOptionSelector";
import { productSectionsPopover } from "../../utils/state/devicesState";
import { useAtom } from "jotai";

const ProductSubNav = ({ product, sections, sx }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const [popoverOpen, setPopoverOpen] = useAtom(productSectionsPopover)

    return (
        <Stack
            spacing={1.5}
            sx={sx}
        >
            <Divider />
            <Stack
                spacing={4}
                direction={{ xs: "row", md: "column" }}
                justifyContent={{ xs: "space-between", md: "flex-start" }}
            >
                <Typography
                    variant="h5"
                    component="h1"
                >
                    {`${product} specs`}
                </Typography>
                {!isMobile ?
                    <Stack spacing={1}>
                        <Typography variant="body2" color={theme.palette.grey[600]}>
                            Jump to
                        </Typography>
                        {sections.map(section => (
                            <Typography
                                variant="body2"
                                onClick={() => scrollTo(`#${section}`)}
                                sx={{
                                    cursor: "pointer",
                                    flexGrow: 0,
                                    width: "fit-content"
                                }}
                            >
                                {section}
                            </Typography>
                        ))}
                    </Stack> :
                    <PopperOptionSelector
                        open={popoverOpen}
                        setOpenState={setPopoverOpen}
                        closeOnClickAway
                        label="Jump to"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <List
                            dense
                            sx={{
                                padding: theme.spacing(0.5)
                            }}
                        >
                            {sections.map(section => (
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            setPopoverOpen(null)
                                            scrollTo(`#${section}`)
                                        }}
                                    >
                                        <ListItemText>
                                            {section}
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </PopperOptionSelector>
                }
            </Stack>
        </Stack>
    )
}

export default ProductSubNav