import { Divider, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const ProductSection = ({ children, sectionTitle }) => {
    const theme = useTheme()
    return (
        <section
            id={sectionTitle}
        >
            <Stack
                spacing={1}
            >
                <Divider />
                <Typography
                    variant="h6"
                    component="h2"
                    id={sectionTitle}
                >
                    {sectionTitle}
                </Typography>
                <Stack
                    spacing={5}
                    sx={{
                        marginTop: `${theme.spacing(3)} !important`
                    }}
                >
                    {children}
                </Stack>
            </Stack>
        </section>
    )
}

export default ProductSection