import React from "react";
import { useTheme, Stack } from "@mui/material";

const Card = ({ children, disableHover, padding = "large", sx }) => {
    const theme = useTheme()
    const paddingSize = {
        large: "24px",
        small: "16px"
    }
    return (
        <Stack
            sx={{
                padding: paddingSize[padding],
                backgroundColor: theme.palette.surface.raised,
                borderRadius: "16px",
                color: theme.palette.common.white,
                justifyContent: "space-between",
                ...!disableHover && {
                    "&:hover": {
                        background: theme.palette.surface.hover
                    }
                },
                ...sx
            }}
        >
            {children}
        </Stack>
    )
}

export default Card