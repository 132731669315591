import React from "react";
import _ from "lodash";
import ProductDataSection from "./ProductDataSection";

const ProductPageCameraSection = ({ product }) => {
    const cameraTypes = Object.keys(product?.Camera).filter(camera => product?.Camera[camera])

    return cameraTypes.map(camera => {
        const cameraDetails = product?.Camera[camera]
        const keysToFilterOut = ["Resolution", "Resolution__H_x_W_", "Equivalent_Focal_Length", "Aperture__W_", "Aperture__T_", "Video_Resolution", "Zoom", "Features"]
        const otherItemsWithoutFeatures = Object.keys(cameraDetails).filter(key => !keysToFilterOut.includes(key)).map(key => {
            const value = cameraDetails[key]
            return ({
                fieldName: key,
                value: value && value
            })
        })
        const getFeatures = () => {
            if (cameraDetails?.Features.includes(",")) {
                return cameraDetails.Features.split(",").map(item => ({ value: item }))
            }
            return [{ value: cameraDetails.Features }]
        }
        const otherItems = [
            ...otherItemsWithoutFeatures,
            ...cameraDetails.Features ? getFeatures() : []
        ]
        const heroItem = {
            fieldName: "Resolution",
            value: cameraDetails?.Resolution,
            ...cameraDetails?.Resolution__H_x_W_ && {
                additionalDetails: cameraDetails.Resolution__H_x_W_
            }
        }
        const pullOutItems = [
            {
                fieldName: "Equivalent Focal Length",
                value: cameraDetails?.Equivalent_Focal_Length,
            },
            {
                fieldName: "Aperture",
                value: cameraDetails?.Aperture__W_,
                ...cameraDetails?.Aperture__T_ && {
                    additionalDetails: cameraDetails.Aperture__T_
                }
            },
            {
                fieldName: "Video Resolution",
                value: cameraDetails?.Video_Resolution
            },
            {
                fieldName: "Zoom",
                value: cameraDetails?.Zoom
            }
        ]


        return (
            <ProductDataSection
                groupName={_.startCase(camera)}
                heroItem={heroItem}
                pullOutItems={pullOutItems}
                otherItems={otherItems}
            />
        )
    })
}

export default ProductPageCameraSection