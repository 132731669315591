import React, { useState } from "react";
import { Box, Paper, Typography, Stack, Popper, ClickAwayListener } from "@mui/material";
import Button from "./Button";
import { theme } from "../../themes/themes";

const PopoverOptionSelector = ({
    Icon,
    open,
    children,
    tooltipTitle,
    setOpenState,
    closeOnClickAway = false,
    label,
    iconPosition = "left",
    value,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        if (!setOpenState) {
            setAnchorEl(event.currentTarget);
            return
        }

        setOpenState(event.currentTarget)
    };

    const handleClose = () => {
        if (!setOpenState) {
            setAnchorEl(null);
            return
        }

        setOpenState(null)
    };

    const handleClickAway = () => {
        if (!closeOnClickAway) {
            return;
        }
        handleClose()
    }

    const isOpen = open ? Boolean(open) : Boolean(anchorEl);
    const id = isOpen ? tooltipTitle : undefined;

    return (
        <Box>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Stack>
                    <Button aria-describedby={id} onClick={handleClick}>
                        <Stack
                            direction={iconPosition === "left" ? "row" : "row-reverse"}
                            alignItems="center"
                            spacing={1}
                        >
                            {Icon && <Icon />}
                            {label &&
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: theme.palette.grey[600],
                                        fontWeight: 400,
                                    }}
                                    noWrap
                                >
                                    {`${label}`}
                                </Typography>
                            }
                            {value &&
                                <Typography
                                    variant="body2"
                                    sx={{
                                        textTransform: "capitalize"
                                    }}
                                    noWrap
                                >
                                    {value}
                                </Typography>
                            }
                        </Stack>
                    </Button>
                    <Popper
                        id={id}
                        open={isOpen}
                        anchorEl={open ? open : anchorEl}
                        onClose={handleClose}
                        anchorOrigin={anchorOrigin}
                        transformOrigin={transformOrigin}
                        sx={{
                            borderRadius: "8px",
                            zIndex: 1001,
                        }}
                    >
                        <Paper elevation={6}>
                            {children}
                        </Paper>
                    </Popper>
                </Stack>
            </ClickAwayListener>
        </Box>
    )
}

export default PopoverOptionSelector;