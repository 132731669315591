import React from "react";
import ProductDataSection from "./ProductDataSection";

const ProductPageDesignSection = ({ product }) => {
    const design = product?.Design;
    const heroItem = {
        fieldName: "Dimensions",
        value: " ",
        additionalDetails: `Width: ${design?.Width} x Height: ${design?.Height} x Depth: ${design?.Thickness}`,
    }
    const pullOutItems = [
        {
            fieldName: "Weight",
            value: design?.Weight,
        },
        {
            fieldName: "IP Rating",
            value: design?.IP_Rating,
        },
        {
            fieldName: "Colors",
            value: design?.Color_s_,
        },
    ]


    return (
        <ProductDataSection
            heroItem={heroItem}
            pullOutItems={pullOutItems}
        />
    )
}

export default ProductPageDesignSection