import React from "react";
import ProductDataSection from "./ProductDataSection";

const ProductPageDisplaySection = ({ product }) => {
    const display = product.Display;
    const heroItem = {
        fieldName: "Viewport Resolution (dp)",
        value: `${display.dppx}px`,
        ...display.Resolution__H_x_W_ && {
            additionalDetails: `${display?.Resolution__H_x_W_} native resolution`
        }
    }
    const pullOutItems = [
        {
            fieldName: "Scale Factor",
            value: display.xxhdpi,
        },
        {
            fieldName: "Display Dimensions",
            value: `${display?.Diagonal} diagonal`,
            ...(display?.Width && display?.Height) && {
                additionalDetails: `${display.Width} x ${display.Height} (W x H)`
            }
        },
        {
            fieldName: "Pixel Density",
            value: display?.Pixel_Density
        },
        {
            fieldName: "Refresh Rate",
            value: display?.Refresh_Rate
        }
    ]
    const keysToFilterOut = ["dppx", "Resolution__H_x_W_", "xxhdpi", "Diagonal", "Width", "Height", "Pixel_Density", "Refresh_Rate"]
    const otherItems = Object.keys(display).filter(key => !keysToFilterOut.includes(key)).map(key => {
        const value = display[key]
        return ({
            fieldName: key,
            value: value && value
        })
    })

    return (
        <ProductDataSection
            heroItem={heroItem}
            pullOutItems={pullOutItems}
            otherItems={otherItems}
        />
    )
}

export default ProductPageDisplaySection