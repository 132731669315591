import React from "react";
import ProductDataSection from "./ProductDataSection";

const ProductPageTechSection = ({ product }) => {
    const tech = product?.Inside
    const heroItem = {
        fieldName: "Processor (Chip)",
        value: tech?.Processor?.CPU,
        additionalDetails: tech?.Processor?.CPU_Clock_Speed,
    }
    const pullOutItems = [
        {
            fieldName: "RAM",
            value: tech?.RAM?.Type,
            additionalDetails: tech?.RAM?.Clock_Speed,
        },
        {
            fieldName: "Storage",
            value: tech?.Storage?.Capacity,
        },
        {
            fieldName: "Battery",
            value: tech?.Battery?.Capacity,
            ...(tech?.Battery?.Talk_Time && tech?.Battery?.Talk_Time) && {
                additionalDetails: `${tech.Battery.Talk_Time} talk time`
            }
        }
    ]

    const buildGPUString = () => {
        if (tech?.Processor?.GPU_Clock_Speed && !tech?.Processor?.GPU_Dedicated_Memory) {
            return `${tech?.Processor?.GPU} ${tech?.Processor?.GPU_Clock_Speed}`
        } else if (tech?.Processor?.GPU_Clock_Speed && tech?.Processor?.GPU_Dedicated_Memory) {
            return `${tech?.Processor?.GPU} ${tech?.Processor?.GPU_Clock_Speed} ${tech?.Processor?.GPU_Dedicated_Memory}`
        }
        return tech?.Processor?.GPU
    }

    const otherItems = [
        { value: tech?.Software?.OS_Version },
        { value: tech?.Sensor_s_?.Present },
        tech?.Processor?.GPU && { value: buildGPUString() }
    ]
    return (
        <ProductDataSection
            heroItem={heroItem}
            pullOutItems={pullOutItems}
            otherItems={otherItems}
        />
    )
}

export default ProductPageTechSection